.inviteContainer {
  padding: 48px;
  background: #f2f6f4;
  height: 100vh;
  overflow-y: auto;
  .inv-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .inv-body {
    padding: 24px 0;
  }

  .btnContainer {
    display: flex;
    justify-content: flex-start;
    gap: 16px;

    .primaryBtn,
    .outlinedBtn {
      width: fit-content;
      padding: 15px;
      border-radius: 20px;
    }
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      > * {
        width: 100% !important;
      }
    }
  }
}
