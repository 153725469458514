.settingContainer {
  min-height: 100vh;
  height: 100%;
  background: #f2f6f4;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
  max-width: 30%;
  margin-bottom: 20px;
}
.MuiTextField-root p {
  color: rgb(255, 0, 0);
}
