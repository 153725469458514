.all-profile-container {
  display: flex;
  padding: 15px 30px 15px 15px;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;

  h4 {
    font-size: 22px;
  }
  .user-info {
    width: 30%;
    min-width: 340px;
  }
  .AT-connect {
    width: 66%;
  }
  .conntect-option {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 15px;

    a {
      font-size: 14px;
      text-decoration: none;
    }
  }

  .connect-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    p {
      max-width: 60%;
    }
  }
}
.conected-card {
  background: white;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
}
.conected-labs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    max-width: 95px;
    height: auto;
  }
  .lab-details {
    display: flex;
    align-items: center;
    gap: 25px;
    width: 90%;
  }
}
.connect-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  h4 {
    font-size: 22px;
  }
}
.connect-with-evi {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;

  .conected-card {
    width: 76%;
    margin-bottom: 0;
  }
  .sync-evi {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    width: 21%;
  }
}
.connect-form-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 40px;
  .MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgb(0, 0, 0);
  }
}
.form-1 {
  .connect-form-content {
    gap: 0px;
  }
  .conected-labs {
    .lab-details {
      cursor: pointer;
    }
    img {
      max-width: 70px;
    }
  }
}
.form-2 {
  .conected-labs {
    img {
      max-width: 58px;
    }
  }
  h4 {
    font-family: $bodyFont;
  }
}
.input-bg {
  background: #f2f6f4;
}
.success-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.connect-form-footer {
  gap: 20px;
  a,
  button {
    min-width: 30%;
    border-radius: 25px;
  }
}
