.c-alert-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .c-header {
    display: flex;
    gap: 11px;
  }
  .c-footer {
    button {
      text-transform: none;
    }
  }
}
.invite-ca {
  margin-bottom: 15px;
}
.invite-ca-top {
  margin-top: 15px;
}
