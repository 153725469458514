.medical-card-panel {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  .m-card {
    cursor: pointer;
    width: calc(50% - 9px);
    @media screen and (max-width: 950px) {
      width: 100%;
    }
    background: white;
    padding: 16px;
    border-radius: 16px;
    .m-card-head {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      h5 {
        font-size: 16px;
        line-height: 24px;
        color: black;
      }
    }
    .m-card-sub-list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .medical-list {
        display: flex;
        justify-content: space-between;
        p {
          color: #79767d;
          font-size: 16px;
          line-height: 24px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #e7e0ec;
        }
      }

      .more {
        color: $primaryColor;
      }
    }
  }
}
