.avatar {
  width: 64px;
  height: 64px;

  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primaryColor;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  img {
    position: absolute;
    bottom: 0;
    right: -7px;
  }
}
.evi-bg {
  background: #d2e9df;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.non-evi-bg {
  background-color: #ffffff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #d2e9df;
}
p.new-patient {
  position: absolute;
  top: -7px;
  left: -4px;
  font-size: 10px;
  background: #f01eaa;
  color: white;
  font-weight: bold;
  padding: 4px 6px;
  margin: 0;
  line-height: 1;
  border-radius: 10px;
}
.group-mask {
  position: absolute;
  width: 98%;
  height: 100%;
  background: white;
  z-index: 1;
  bottom: -9px;
  left: 1%;
  border-radius: 9px;
}
.patient-list-card {
  display: flex;
  gap: 16px;
  padding: 12px 8px;
  background: white;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  box-shadow: 2px 1px 8px #eaeaea;

  .avatar {
    min-width: 64px;
  }

  .content {
    color: #1d1b20;
    width: 80%;
    .p-name {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      a {
        display: flex;
      }
    }
    @media screen and (max-width: 1200px) {
      width: 60%;
    }

    .p-name {
      img {
        position: absolute;
        right: 2%;
        top: 20%;
      }
    }

    .date,
    .testName {
      color: #79747e;
      overflow-wrap: anywhere;
    }
  }
}

.testList {
  flex-direction: column;
  gap: 8px;

  .testResultCard {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
  }

  .avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logoIco {
    width: 17px;
  }

  .testIco {
    position: static;
  }

  .content {
    width: 94%;
  }

  .date {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
    }
  }
}
.test-indicator-result {
  position: absolute;
  top: 8px;
  transform: translateX(-35%);
}
.test-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .test-card-options {
    display: flex;
    align-items: center;
    gap: 10px;

    .downloadBtn {
      padding: 0;
      display: flex;
      justify-content: flex-end;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.indicator {
  width: 15px;
  height: 15px;
  background: #f01eaa;
  border-radius: 100%;
}
.list-for-remove {
  flex-direction: column;
  .patient-list-card {
    width: 100%;
  }
}
