.post-card {
  border-radius: 8.584px;
  display: flex;
  flex-direction: column;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  gap: 10px;
  padding: 13px;
  background-color: #e6e5ff;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;

  div {
    height: 193px;
    border-radius: 8.584px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  h4 {
    color: black;
    width: 99%;
    padding: 0;
    font-size: 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.swiper-pagination-horizontal {
  bottom: 0px !important;
}
.custom-nav {
  position: absolute;
  top: 0;
  z-index: 2;
  left: 0px;
  display: flex;

  width: 40%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 32px;
  }
  h3 {
    font-size: 22px;
  }
  .nav-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    button {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: #d2e9df;
      border-radius: 100%;
      border: none;
      cursor: pointer;
    }
  }
}
.swiper-pagination-bullet-active {
  background-color: $primaryColor !important;
}
.swiper-button-lock {
  display: none !important;
}
.post-details-img {
  max-width: 85%;
  height: auto;
}
.post-html-content {
  margin-bottom: 30px;
  max-width: 85%;
  text-align: justify;
  font-size: 16px;
}
.post-header-panel {
  justify-content: space-between;
  max-width: 99%;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
  }
}
