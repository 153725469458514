// body * {
//   outline: 1px solid red;
// }
.acco-links {
  box-shadow: none !important;
  color: #49454f !important;
  padding: 16px 6px 16px 16px !important;

  span {
    font-family: "Tenor Sans", sans-serif !important;
    color: #49454f !important;
  }
  svg {
    color: #49454f !important;
  }
  .MuiAccordionSummary-contentGutters {
    margin: 0 !important;
  }
  ul.acc-sub-menu {
    list-style: none;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    li {
      a {
        padding: 0;
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            font-size: 18px;
            color: #49454f;
            line-height: 24px !important;
          }
        }
      }
    }
  }
}
.acco-links.Mui-expanded {
  position: relative;
  background-color: transparent;
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 64px;
    background-color: #fee5f7;
    top: 0;
    z-index: -1;
    left: 0;

    border-radius: 100px;
  }

  span,
  svg {
    color: #931e69 !important;
  }
  ul.acc-sub-menu li {
    a {
      div {
        span {
          color: #49454f !important;
        }
      }
    }
  }
}
