@media screen and (max-width: 1199px) {
  .MuiGrid-root, .MuiPaper-root, .patient-list-card, .indicatorsCard > *, .settingContainer {
    max-width: 100% !important;
    margin: 0 !important;
width: 100%;
  }

  .patient-list-card, .MuiDrawer-paperAnchorRight{
    width: 100% !important;
  }
  .loginbg {
    display: none;
  }
  #demo-positioned-menu{
    left:20% !important;
  }
  .reverse-mob{
    flex-direction: column-reverse !important;
    
  }
  .profileCardContainer{
    min-height: fit-content !important;
    padding: 24px !important;
  }
  .contentArea {
    padding: 24px !important;
}
.fw-mobile{
  flex-direction: column;
   > * {
    width: 100% !important;
   }
}
}
