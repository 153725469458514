.noUnderLine {
  text-decoration: none;
}

.form-group {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
}
.d-flex-jc-ac {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.d-flex-ac {
  display: flex;
  gap: 5px;
  align-items: center;
}
.normal-link-ico {
  display: flex;
  color: #79767d;
  gap: 4px;
  width: fit-content;
  cursor: pointer;
  p {
    font-size: 17px;
  }
}
.mb-40 {
  margin-bottom: 40px;
}
.vertical-form-group {
  * {
    margin: 0 !important;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
// loader
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #757575;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custome-alert {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 12px;
  border-radius: 15px;
}
.error {
  background: #ffe5f6;
}
