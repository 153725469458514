.breadcrumbs {
  .active {
    color: black;
  }

  .prev {
    color: #79767d;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 7px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
  display: flex;
  align-items: center;
  .arrow-container {
    background: #d2e9df;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.custom-breadcrumb {
  display: inline-block;
  padding: 0 15px;
  position: absolute;
  top: 17px;
  z-index: 1200;
  width: 70%;
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.finding-breadcrumb {
  @media screen and (max-width: 600px) {
    padding: 0;
  }
}
.concat-title {
  .active {
    max-width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black;
    @media screen and (min-width: 500px) and (max-width: 1300px) {
      max-width: 20%;
    }
  }
}
