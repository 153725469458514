.lb-container,
.lb-header {
  background-color: #f2f6f4 !important;
  box-shadow: none !important;
}
.lb-header .lb-button {
  background-size: 95% auto !important;
}
.lb-icon-close {
  background-image: url("../../images/close.svg") !important;
  &:hover {
    background-color: transparent !important;
  }
}
.lb-icon-zoomin {
  background-image: url("../../images/zoomin.svg") !important;
  &:hover {
    background-color: transparent !important;
  }
}
.lb-icon-zoomout {
  background-image: url("../../images/zoomout.svg") !important;
  &:hover {
    background-color: transparent !important;
  }
}

.custom_mask {
   background-color: #f2f6f4 !important;
}
.custom_wrap {
  //padding-bottom:150px !important;
}

.gallery_button {
  display: block;
  cursor: pointer;
  z-index: 50;
}




.PhotoView__Photo {
  object-fit: contain !important;
  padding-bottom: 150px !important;
  padding-top:50px !important;
  background-color: 'green';
}

.PhotoView__PhotoBox {
 //transform: none !important;
}
.custom_wrap {
 //transform: none !important;

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #05735A;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  
}

.PhotoView-Slider__ArrowLeft {
    display: none !important;
}
.PhotoView-Slider__ArrowRight {
    display: none !important;
}



