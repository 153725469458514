/* Scrollbar container */
.scrollbar-container {
  flex-grow: 1;
  position: relative;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 0 10px;
}

/* Custom scrollbar */
.scrollbar {
  position: absolute;
  height: 100%;
  background-color: #888;
  border-radius: 4px;
  cursor: pointer;
}

/* Scrollbar hover effect */
.scrollbar:hover {
  background-color: #555;
}
.test-group-lable {
  border-radius: 50px;
  background: #d2e9df;
  font-size: 16px;
  padding: 8px 12px;
}
