.notifications {
  padding: 6%;
  height: 100vh;
  overflow-y: auto;
}
.empty-notification{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75vh;
  img{
    width: 350px
  }
  button{
    width: fit-content;
    text-transform: none;
    border-radius: 20px;
  }
}
.confirm-clear-notifications {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  .btn-group {
    display: flex;
    gap: 15px;
  }
  .warning-msg {
    display: flex;
    background: #FFEDAB;
    color: #7C5705;
    padding: 12px;
    border-radius: 12px;
    gap:15px ;
    margin: 20px 0;
    p{
      color: #7C5705;
    }
  }
}