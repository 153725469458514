.primaryBtn {
  background-color: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: white;
  border-radius: 100px;
  opacity: 1;
  transition: all 0.5s;
  text-decoration: none;
  padding: 10px 24px !important;
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  min-width: fit-content;
  border-color: transparent;
  line-height: 16px;
  max-height: 40px;
  height: 40px;
  border-radius: 20px;
  text-transform: none !important;
  &:hover {
    opacity: 0.8;
    transition: all 0.5s;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.2;
  }
  @media screen and (max-width: 600px) {
    padding: 10px !important;
  }
}

.outlinedBtn {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #79747e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: $primaryColor;
  border-radius: 100px;
  opacity: 1;
  transition: all 0.5s;
  text-decoration: none;
  padding: 10px 24px !important;
  line-height: 16px;
  max-height: 40px;
  height: 40px;
  &:hover {
    opacity: 0.8;
    transition: all 0.5s;
    cursor: pointer;
  }
}
.menu-seperator {
  height: 14px;
  width: 2px;
  background: #7a7289;
  margin-left: 8px;
}
