.react-pdf-panel2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.viwer-header2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;
  align-items: center;
  .pdf-controler {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    cursor: pointer;
    .btn-bg {
      background: #d2e9df;
      display: flex;
      padding: 5px;
      border-radius: 50%;
    }
    .disabled {
      opacity: 0.5;
      background: rgba(29, 27, 32, 0.12);
    }
  }
}
.react-pdf__Page {
  cursor: grab;
}
