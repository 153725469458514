.loginbg {
  background-image: url(../../images/lgoin-register-bg.png);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.loginForm-container {
  background-color: #f2f6f4;
  overflow-y: auto;
}

.forgetPassword {
  display: flex;
  padding: 10px 12px 10px 2px;
  justify-content: flex-start;
  width: fit-content;
}

.loginForm {
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  min-width: 521px;
  max-width: 521px;
  > img {
    margin-bottom: 32px;
    margin-left: -8px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
}
.login-register-steps {
  max-width: 328px !important;
  min-width: 328px !important;
}
.profileForm {
  @media screen and (max-width: 600px) {
    width: 90%;
    .css-mhc70k-MuiGrid-root > .MuiGrid-item {
      padding-left: 0;
    }
  }
  width: 70%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 521px;

  > img {
    margin-left: -8px;
  }

  .form-container {
    form,
    .form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      input {
        height: 20px;
      }
    }

    * {
      margin: 0;
    }
  }
}

.toggleLoginRegisterArea {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 40px;
  gap: 14px;
  margin-top: 15px;
}

.submit-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.terms-container {
  display: flex;
  align-items: flex-start;
  span {
    padding-top: 3px;
  }
  p {
    padding-top: 3px;
  }

  span {
    color: $primaryColor !important;
  }
}
.selectInput {
  margin: 10px 0px 4px 0px;
}

.registerComplete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .MuiBox-root {
    margin-bottom: 16px;
  }
}

.terms-topic {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 13px;
  ul {
    list-style: none;
    li {
      padding: 5px 0;
    }
  }
}
.loginForm-container {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f6f4 inset !important;
  }
}
.pin-container {
  height: 100%;
  max-width: 70%;
  margin: auto;
  padding: 44px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .pin-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75%;
    gap: 7%;
  }
}
