.sidebarArea {
  padding: 24px 35px 25px 15px;
  min-height: 100vh;
  height: 100%;
  z-index: 22;
  position: relative;
  @media screen and (max-width: 600px) {
    padding: 24px 25px 25px 20px;
  }
  // box-shadow: -2px 4px 12px 0px rgba(121, 116, 126, 0.08);
  img {
    margin-bottom: 50px;
  }

  .sidebar-list {
    display: flex;
    flex-direction: column;

    a {
      font-family: $titleFont;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
      display: flex;
      gap: 12px;
      width: 100%;
      padding: 16px;
      align-items: center;
      color: #49454f;
      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 22px;
        gap: 9px;
        padding: 14px;
      }
    }

    .active {
      background-color: #ffe5f6;
      color: #931e69;
      border-radius: 100px;
    }
  }
}

.top-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  list-style: none;
  margin: 0;
  li.langToggle {
    display: flex;
    gap: 12px;
    text-transform: capitalize;
    a {
      text-decoration: none;
    }
  }
}

.seperateNav {
  padding: 2% 33px 14px 33px;
  margin: 0;
  background: #f2f6f4;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-controler {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.contentArea {
  background-color: #f2f6f4;
  min-height: 100vh;
  height: 100%;
  padding: 16px 32px 16px 16px;
}

.pprofile-CA {
  padding: 0px 16px 16px 16px;
}

.tab-container {
  .MuiTabPanel-root {
    padding: 15px 0;
  }
}

.patient-list-container {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;

  .patient-list-card {
    width: calc(33.67% - 18px);
    cursor: pointer;
  }
}

.search-area {
  position: relative;
  margin: 16px 0px 8px 0px;

  input {
    width: 100%;
    height: 50px;
    padding-left: 40px;
    border: 0;
    background: #e2eae6;
    border-radius: 24px;
    font-size: 16px;
    &:focus-visible {
      outline-color: #05735a;
      outline-style: solid;
      outline-width: 1px;
    }
  }

  .searchico {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
  .closeico {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }
}

.profileCardContainer {
  min-height: 100vh;
  height: 100%;
  background: #f2f6f4;

  .profileCard {
    border-radius: 16px;
    background: #fff;
    box-shadow: -2px 4px 12px 0px rgba(121, 116, 126, 0.08);
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      max-width: 85%;
      text-align: center;
      overflow-wrap: anywhere;
    }
    h2 {
      overflow-wrap: anywhere;
      padding: 0 15px;
      line-height: 46px;
    }
  }
}

.profileCardListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .docPrev {
    padding: 8px 16px;
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .docItem {
      display: flex;
      gap: 10px;
      justify-content: flex-start;

      .info {
        color: #79767d;
      }
    }
  }
}

// test results docs
.docfile {
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
  .file-panel {
    width: 133px;
    .img-panel {
      .thumb {
        max-height: 134px;
        overflow: hidden;
        cursor: pointer;
      }
    }
    p {
      padding: 5px 10px;
      line-height: 20px;
      max-width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    @media screen and (max-width: 600px) {
      flex: 0 0 45%;
      width: 45%;
      max-height: 130px;
      overflow: hidden;
    }
  }
}

// test result

// indicator
.testHeader {
  display: flex;
  background: #f2f6f4;
  padding: 15px 0;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 600px) {
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 15px;
  }
}

.test-indicator-card {
  background: white;
  border-radius: 11px;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-size: 16px;
  }
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .degree {
      font-size: 28px;

      span.unit {
        color: #79747e;
        font-size: 16px;
        margin-left: 7px;
      }
    }

    .test-indicator {
      width: 60%;
      height: 6px;
      position: relative;
      background: rgb(251, 96, 96);
      background: linear-gradient(
        90deg,
        rgba(251, 96, 96, 1) 0%,
        rgba(251, 96, 96, 1) 11%,
        rgba(253, 162, 87, 1) 14%,
        rgba(253, 162, 87, 1) 23%,
        rgba(255, 254, 163, 1) 27%,
        rgba(255, 254, 163, 1) 36.5%,
        rgba(169, 232, 197, 1) 38.5%,
        rgba(169, 232, 197, 1) 61.5%,
        rgba(255, 254, 163, 1) 63.5%,
        rgba(255, 254, 163, 1) 73%,
        rgba(253, 162, 87, 1) 77%,
        rgba(253, 162, 87, 1) 85.5%,
        rgba(251, 96, 96, 1) 89.5%,
        rgba(251, 96, 96, 1) 100%
      );

      .indicator-line {
        position: absolute;
        width: 2px;
        height: 23px;
        background: #7e7e7e;
        top: -7px;
      }
    }
    .startByZero {
      width: 60%;
      height: 6px;
      position: relative;
      background: rgb(251, 96, 96);
      background: linear-gradient(
        90deg,
        rgba(251, 96, 96, 0) 0%,
        rgba(251, 96, 96, 0) 11%,
        rgba(253, 162, 87, 0) 14%,
        rgba(253, 162, 87, 0) 23%,
        rgba(255, 254, 163, 0) 27%,
        rgba(255, 254, 163, 0) 36.5%,
        rgba(169, 232, 197, 1) 36.5%,
        rgb(169, 232, 197, 1) 61.5%,
        rgba(255, 254, 163, 1) 63.5%,
        rgba(255, 254, 163, 1) 73%,
        rgba(253, 162, 87, 1) 77%,
        rgba(253, 162, 87, 1) 85.5%,
        rgba(251, 96, 96, 1) 89.5%,
        rgba(251, 96, 96, 1) 100%
      );

      .indicator-line {
        position: absolute;
        width: 2px;
        height: 23px;
        background: #7e7e7e;
        top: -7px;
      }
    }
    .endByZero {
      width: 60%;
      height: 6px;
      position: relative;
      background: rgb(251, 96, 96);
      background: linear-gradient(
        90deg,
        rgba(251, 96, 96, 1) 0%,
        rgba(251, 96, 96, 1) 11%,
        rgba(253, 162, 87, 1) 14%,
        rgba(253, 162, 87, 1) 23%,
        rgba(255, 254, 163, 1) 27%,
        rgba(255, 254, 163, 1) 36.5%,
        rgba(169, 232, 197, 1) 38.5%,
        rgba(169, 232, 197, 1) 61.5%,

        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 0%
      );

      .indicator-line {
        position: absolute;
        width: 2px;
        height: 23px;
        background: #7e7e7e;
        top: -7px;
      }
    }
  }
}

.indicatorsCard {
  flex-wrap: wrap;

  > * {
    width: 49%;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}

.result-title {
  background: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 10px;
  flex-basis: 100%;
  margin-top: 16px !important;
  p {
    color: #79767d;
  }

  h2 {
    font-size: 22px;
    color: #79767d;
  }
}

.test-description-panel {
  background: white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;

  .desc-header {
    display: flex;
    justify-content: space-between;

    .header-topic {
      display: flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 800px) {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .desc-body {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .topics-row {
      display: flex;
      gap: 3%;
      @media screen and (max-width: 800px) {
        gap: 14px;
        flex-direction: column;
      }
      .topic {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        div {
          display: flex;
          gap: 7px;
          justify-content: flex-start;
          flex-wrap: wrap;
          h4 {
            color: black;
          }
          p {
            color: #79767d;
          }
        }
      }
    }
  }
}
.historical-container {
  padding: 16px 6%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f2f6f4;
  height: 90%;
  max-height: 90%;
  overflow-y: auto;
}
.ai-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h3 {
    font-size: 16px;
    line-height: 20px;
  }
  > ul {
    list-style: none;
    padding: 0;
    > li {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
.no-patient-list {
  .section-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .section-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 54px;
    padding-top: 60px;
    justify-content: center;
    flex-wrap: wrap;
    img {
      max-width: 100%;
    }

    @media screen and (min-width: 1024px) {
      max-width: 93%;
      margin: auto;
      p {
        max-width: 520px;
      }
    }
  }
}
.merge-panel {
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: stretch;
  .profileCard {
    width: 47%;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}
.autocomplete-pl {
  padding-left: 0 !important;
}
.bookmarks-links {
  padding: 20px 0;
  border-bottom: 1px solid #acc0b8;
  margin: 10px 0 20px;
  a {
    padding: 20px 15px;
    text-decoration: none;
  }
  a.active {
    border-bottom: 3px solid #12735a;
  }
}
.iconBtn {
  color: rgb(5, 115, 90);
  background: #d2e9df;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
