.c-card {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  background: #fff;
  box-shadow: -2px 4px 12px 0px rgba(121, 116, 126, 0.08);
  padding: 16px;
  align-items: center;
}
.icon-before-panel {
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
}
