@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Tenor+Sans&display=swap");

$titleFont: "Tenor Sans", sans-serif;
$bodyFont: "Roboto", sans-serif;
$primaryColor: #05735a;
body,
html {
  margin: 0;
  padding: 0;
}

a {
  color: $primaryColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titleFont;
  font-weight: 400;
  // text-transform: capitalize;
}
h2 {
  font-size: 32px;
  @media screen and (max-width: 600px) {
    font-size: 25px;
  }
}
div,
span,
p,
li,
a,
button {
  font-family: $bodyFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

div,
span,
p,
li,
a,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  @media screen and (max-width: 600px) {
    font-size: 25px;
  }
}
.mt-15 {
  margin-top: 15px;
}
hr {
  width: 100%;
  height: 2px;
  border-color: transparent;
  background: #e8def8;
}
header {
  border-left: 1px solid #e4e4e4;
}
.MuiDrawer-root.MuiDrawer-docked {
  min-height: 100vh !important;
  height: 100% !important;
}

.css-nyms58-MuiDrawer-docked .MuiDrawer-paper {
  height: 100% !important;
  min-height: 100vh !important;
  width: 280px !important;
}

.MuiGrid-container {
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.evi-logo {
  max-width: 100%;
  width: 290px;
  height: auto;
  cursor: pointer;
}
.MuiFormLabel-root {
  color: #49454f !important;
}
p {
  color: #49454f;
}

fieldset {
  legend {
    span {
      font-size: unset;
    }
  }
}
.selectElement.lg {
  fieldset {
    legend {
      span {
        font-size: medium;
      }
    }
  }
}
.selectElement.sm,
.selectElement.md {
  fieldset {
    legend {
      span {
        font-size: x-small;
      }
    }
  }
}

// .selectElement{
//   fieldset{

//       font-size: x-large ;
//     }

// }
// .selectElement.lg{
//   fieldset{

//     font-size: xx-large ;
//     legend{
//       font-size: 0.65em;
//     }
//   }
// }
// .selectElement.md{
//   fieldset{

//     font-size: medium ;

//   }
// }
.inv-header {
  line-height: 40px;
}
.editButton {
  display: flex;
  background: #e2eae6;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5px;
  z-index: 2;
  right: -13px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.MuiSelect-select {
  height: 20px !important;
  min-height: 20px !important;
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

.loading-area {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.photo-container {
  width: 112px;
  height: 112px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
}
.MuiButtonBase-root.MuiTab-root {
  text-transform: none !important;
}
.opacityColor {
  display: flex;
  align-items: center;
  gap: 2px;
  opacity: 0.38;
}
.react-pdf__Page__annotations {
  display: none !important;
}

.input:-internal-autofill-selected {
  background-color: transparent !important;
}
