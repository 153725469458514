.addDoc-panel {
  padding: 48px;
  background: #f2f6f4;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  button {
    text-transform: none;
  }
  .doc-form {
    .MuiContainer-root {
      padding: 0;
    }
    .MuiGrid-root {
      padding-left: 0;
    }
  }
}
.upload-area {
  background: #e2eae6;
  border: 2px dashed #05735a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 8px;
  gap: 8px;
}
.file-upload {
  max-width: 600px;
  margin: 0 auto;
}

.dropzone {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.file-limit {
  color: green;
}

.browse-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

.file-info {
  font-size: 12px;
  color: grey;
  margin-top: 10px;
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.thumbnail {
  position: relative;
  width: 100px;
  text-align: center;
  button {
    padding: 0;
  }
}

.thumbnail img,
.thumbnail embed {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.thumbnail .file-name {
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.actions button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.actions .cancel-button {
  background-color: grey;
  color: white;
}
.uploaded-files {
  max-height: 322px;
  overflow-y: auto;
}
.delete-btn {
  position: absolute;
  bottom: 40px;
  right: 3px;
  cursor: pointer;
}
.downloadBtn {
  .MuiLoadingButton-loadingIndicator {
    color: $primaryColor;
  }
}
